import React from 'react';

import Head from '../components/Head';
import Layout from '../components/layout';
import Home from '../containers/HomePage';

const HomePage = () => (
  <Layout>
    <Head
      title="뱅크샐러드 공식 블로그 | 메인"
      description="뱅크샐러드의 공식 블로그입니다. 뱅크샐러드의 기술, 문화, 뉴스, 행사 등 최신 소식 및 뱅크샐러드가 겪은 다양한 경험을 공유합니다."
      keywords={[
        '뱅크샐러드 블로그',
        '기술블로그',
        '핀테크',
        '스타트업',
        '개발자',
        '디자이너',
        'PM',
        '기획자',
        '입사',
        '뱅샐',
        'banksalad',
        '뱅크샐러드뉴스',
        '채용',
        '데일리라이프',
        '일상',
        '복지',
        '혜택',
      ]}
    />
    <Home />
  </Layout>
);

export default HomePage;
