import { Link } from 'gatsby';
import React from 'react';
import { IoMdArrowRoundForward } from 'react-icons/all';

import S from './styles';

interface PageNameProps {
  name: string;
  link: string;
  isLast?: boolean;
}

export const PageButton = ({ name, link, isLast }: PageNameProps) => (
  <>
    <S.layout>
      <S.buttonWrap />
      <S.contents>{name} 페이지로 이동</S.contents>
      <S.buttonWrap>
        <Link to={`/${link}`} aria-label="next">
          <IoMdArrowRoundForward />
        </Link>
      </S.buttonWrap>
    </S.layout>
    {isLast && <div style={{ width: '100%', height: '80px' }} />}
  </>
);
