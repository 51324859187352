import React from 'react';

import { BannerInner, BannerSlogan, BannerWrapper } from './style';

type BannerProps = {};

const Banner: React.FunctionComponent<BannerProps> = () => (
  <BannerWrapper>
    <BannerSlogan />
    <BannerInner />
  </BannerWrapper>
);

export default Banner;
