import styled from 'styled-components';
import { themeGet } from 'styled-system';

const S = {
  layout: styled.div`
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 990px) {
      width: 900px;
    }
    @media (min-width: 1200px) {
      width: 1050px;
    }
    @media (min-width: 1400px) {
      width: 1170px;
    }
    @media (max-width: 990px) {
      padding: 0 45px;
    }
    @media (max-width: 575px) {
      padding: 0 25px;
    }
  `,
  contents: styled.div``,
  buttonWrap: styled.div`
    min-width: 32px;
    a {
      width: 32px;
      height: 32px;
      color: ${themeGet('colors.textColor', '#292929')};
      border-radius: 50%;
      background-color: #f3f3f3;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.15s ease-in-out;
      svg {
        display: block;
      }
      &:hover {
        color: #fff;
        background-color: ${themeGet('colors.primary', '#00c68e')};
      }
    }
  `,
};

export default S;
