import React from 'react';

import { BLOG_CATEGORY } from '../../config';
import { BlogContentsSelector } from '../../hooks/data/types';
import MinimalPostCards from '../MinimalPostCards';
import Pagination from '../Pagination/pagination';
import BlogPostsWrapper, { SecTitle } from './style';

interface Props {
  category: BLOG_CATEGORY;
  contentsSelector: BlogContentsSelector;
  pagination?: boolean;
  mainTitle?: string;
  postLimit?: number;
}

const PostListView: React.FC<Props> = ({
  category = BLOG_CATEGORY.ALL,
  pagination = true,
  mainTitle,
  postLimit,
  contentsSelector,
}) => {
  const {
    posts,
    pageInfo: { current, total },
  } = contentsSelector();
  const hasMorePosts = total > 1;
  const pagePath = category === BLOG_CATEGORY.ALL ? '' : `/${category}`;
  const renderPagination = () =>
    hasMorePosts && (
      <Pagination nextLink={`${pagePath}/page/2`} currentPage={current} totalPage={total} />
    );

  return (
    <BlogPostsWrapper>
      <SecTitle>{mainTitle || 'Stories'}</SecTitle>
      <MinimalPostCards posts={postLimit ? posts.slice(0, postLimit) : posts} />
      {pagination && renderPagination()}
    </BlogPostsWrapper>
  );
};

export default PostListView;
