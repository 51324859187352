import React from 'react';

import PostListView from '../../components/PostListView';
import { BLOG_CATEGORY } from '../../config';
import usePncBlogLatestList from '../../hooks/data/usePncBlogLatestList';
import useTechBlogLatestList from '../../hooks/data/useTechBlogLatestList';
import Banner from './Banner';
import { PageButton } from './PageButton';

const Home: React.FC = () => (
  <div>
    <Banner />
    <PostListView
      category={BLOG_CATEGORY.TECH}
      pagination={false}
      mainTitle="Tech"
      postLimit={3}
      contentsSelector={useTechBlogLatestList}
    />
    <PageButton name="Tech" link="tech" />
    <PostListView
      category={BLOG_CATEGORY.PNC}
      pagination={false}
      mainTitle="Culture"
      postLimit={3}
      contentsSelector={usePncBlogLatestList}
    />
    <PageButton name="Culture" link="pnc" isLast />
  </div>
);

export default Home;
