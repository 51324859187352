import { graphql, useStaticQuery } from 'gatsby';

const usePncBlogLatestList = () => {
  const { allMarkdownRemark, allSitePage } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allSitePage(filter: { path: { eq: "/pnc/page/1" } }) {
        nodes {
          pageContext
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date, frontmatter___weight], order: [DESC, DESC] }
        limit: 15
        filter: { frontmatter: { category: { eq: "pnc" } } }
      ) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 300)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD [<span>] MMM [</span>]")
              title
              description
              tags
              thumbnail {
                childImageSharp {
                  gatsbyImageData(width: 200, height: 200)
                }
              }
            }
          }
        }
      }
    }
  `);

  const posts = allMarkdownRemark.edges;
  const pageInfo = {
    total: allSitePage.nodes[0].pageContext.numPages,
    current: allSitePage.nodes[0].pageContext.currentPage,
  };

  return { allMarkdownRemark, allSitePage, posts, pageInfo };
};

export default usePncBlogLatestList;
