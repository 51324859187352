import React from 'react';

import PostCardMinimal from './PostCardMinimal/postCardMinimal';

interface Props {
  posts: any;
}

const MinimalPostCards: React.FC<Props> = ({ posts }) => {
  const renderPosts = () =>
    posts.map(({ node }: any) => {
      const {
        frontmatter: { title, thumbnail, description, date, tags },
        fields: { slug },
        excerpt,
      } = node;
      const currentTitle = title || slug;

      return (
        <PostCardMinimal
          key={slug}
          title={currentTitle}
          image={thumbnail ? thumbnail.childImageSharp.gatsbyImageData : null}
          url={slug}
          description={description || excerpt}
          date={date}
          tags={tags}
        />
      );
    });

  return renderPosts();
};

export default MinimalPostCards;
